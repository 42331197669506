<script>
  export default {

    name: 'TextNode',

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    computed: {
      tag () {
        return this.item.tag || 'p'
      }
    },

    render (createElement) {
      return createElement(this.tag, {
        'class': this.item.classes,
        style: this.item.styles,
        attrs: this.item.attrs,
      }, this.item.text)
    }
  }
</script>

<style lang="css" scoped>
</style>
