import BaseNode from './BaseNode.vue'
import BaseTextNode from './BaseTextNode.vue'
import DomNode from './DomNode.vue'
import SlideNode from './SlideNode.vue'
import ContentNode from './ContentNode.vue'
import CarouselNode from './CarouselNode.vue'
import SlideshowNode from './SlideshowNode.vue'
import TextNode from './TextNode.vue'

export default (Vue) => {
  var nodeFunctionality = {
    name: 'node',

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    components: {
      BaseNode,
      BaseTextNode,
      DomNode,
      SlideNode,
      ContentNode,
      CarouselNode,
      SlideshowNode,
      TextNode
    },

    render (createElement) {
      return createElement(this.appropriateNodeComponent, {
        props: {
          item: this.item
        }
      })
    },

    computed: {
      appropriateNodeComponent () {
        return this.item.component || 'dom-node'
      }
    }
  }

  Vue.component('node', nodeFunctionality)
}
