<script>
  export default {
    name: 'content-node',

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    render (createElement) {
      var content, self
      self = this
      if (_.has(this.item, 'content')) {
        content = createElement('froalaView', {
          props: {
            value: this.item.content
          }
        })
      }
      return createElement(this.tagElement, {
        'class': this.item.classes,
        style: this.item.styles,
        attrs: this.item.attrs,
        props: {
        }
      }, [content])
    },

    computed: {
      tagElement () {
        return this.item.tag || 'div'
      }
    }

  }
</script>