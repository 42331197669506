<template>
  <div uk-slider="autoplay: true">
    <div class="uk-position-relative">

      <div class="uk-slider-container">
        <div class="uk-slider-items" :class="item.classes">
          <div v-for="child in item.children" :key="child.id">
            <node :item="child"></node>
          </div>
        </div>
      </div>

    </div>
    <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-padding-small"></ul>
  </div>
</template>

<script>
  export default {

    name: 'CarouselNode',

    data () {
      return {

      }
    },

    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="css" scoped>
</style>