<template>
  <div class="uk-inline uk-width-1-1" :class="item.classes" :style="item.styles">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="child in item.children" :key="child.id">
        <node :item="child"></node>
      </swiper-slide>
      <div class="swiper-pagination swiper-pagination-white"  slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
  export default {
    name: 'slide-node',

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        swiperOption: {
          autoplay: true,
          setWrapperSize :true,
          autoHeight: true,
          fadeEffect: {
            crossFade: true
          },
          loop: true,
          pagination: {
            clickable: true,
            el: '.swiper-pagination',
            type: 'bullets',
          }
        }
      }
    }
  }
</script>

<style scoped>
</style>
