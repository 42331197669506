<script>
  export default {
    name: 'dom-node',

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    render (createElement) {
      var children
      if (_.has(this.item, 'children')) {
        children = this.item.children.map(child => {
          return createElement('node', {
            props: {
              item: child
            }
          })
        })
      }
      return createElement(this.tagElement, {
        'class': this.item.classes,
        style: this.item.styles,
        attrs: this.attributesWithoutLink,
        props: {}
      }, [children])
    },

    computed: {
      tagElement () {
        return this.item.tag || 'div'
      },

      attributesWithoutLink () {
        let a = _.omit(this.item.attrs, ['uk-scrollspy'])
        return a
      }
    }
  }
</script>

<style scoped>
</style>
