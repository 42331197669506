import '../theme'

import Vue from 'vue/dist/vue.esm'
import VueFroala from 'vue-froala-wysiwyg'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Node from '../../components/node'

import 'stylesheets/frontend/base'

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

Vue.use(VueFroala)
Vue.use(VueAwesomeSwiper)
Vue.use(Node)

function destroyVue () {
  this.$destroy()
  document.removeEventListener('turbolinks:before-cache', destroyVue)
}

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    el: "[data-behavior='vue']",
    beforeMount: function () {
      this.$originalElement = this.$el.outerHTML
      document.addEventListener('turbolinks:before-cache', destroyVue.bind(this))
    },
    destroyed: function () {
      this.$el.outerHTML = this.$originalElement
    }
  })
})

window.onscroll = function () { scrollFunction() }
function scrollFunction () {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementById('totop').style.display = 'flex'
  } else {
    document.getElementById('totop').style.display = 'none'
  }
}
