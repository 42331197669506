<template>
  <div class="uk-position-relative uk-visible-toggle uk-light" uk-slideshow="autoplay: true">

    <ul class="uk-slideshow-items">
      <div v-for="child in item.children" :key="child.id">
        <node :item="child"></node>
      </div>
    </ul>

    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
  </div>
</template>

<script>
  export default {

    name: 'SlideshowNode',

    data () {
      return {

      }
    },

    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="css" scoped>
</style>